<template>
  <nav class="sub-nav container-lg">
    <div class="sub-nav-box">
      <button
        :class="{ active: active === 'home' }"
        v-on:click="active = 'home'"
      >
        Elemzések
      </button>
      <button
        class="d-block d-md-none"
        :class="{ active: active === 'transactions' }"
        v-on:click="active = 'transactions'"
      >
        Tranzakciók
      </button>
      <button
        :class="{ active: active === 'invoices' }"
        v-on:click="active = 'invoices'"
      >
        Számlák
      </button>
      <button
        :class="{ active: active === 'mustpay' }"
        v-on:click="active = 'mustpay'"
      >
        Követelések
      </button>
    </div>
  </nav>
  <div class="container">
    <div class="accounts-top">
      <div class="account">
        <div class="d-md-none balance">
          {{
            data?.balance?.toLocaleString("hu-HU", { maximumFractionDigits: 0 })
          }}
          <span class="currency">Ft</span>
        </div>
        <div class="account-name">
          <div class="account-switch-box">
            <button
              class="account-switch"
              :class="{ open: accountSwitch }"
              v-on:click="accountSwitch = !accountSwitch"
            >
              <BaseIcon name="arrow" />
            </button>
            <div class="account-switch-panel" :class="{ show: accountSwitch }">
              <button
                v-on:click="select(0, { name: 'IntoMedia Group', id: null })"
                class="account-item"
              >
                <BaseIcon name="office" />
                <div>
                  <div class="account-item-name">IntoMedia Group</div>
                  <div class="account-item-type">Összes nyilvános csoport</div>
                </div>
              </button>
              <button
                v-on:click="select(1, group)"
                class="account-item"
                v-for="group of groups"
                :key="group.id"
              >
                <BaseIcon :name="group.private ? 'lock' : 'stack'" />
                <div>
                  <div class="account-item-name">{{ group.name }}</div>
                  <div class="account-item-type">
                    {{ group.private ? "Saját csoport" : "Csoport" }}
                  </div>
                </div>
              </button>
              <button
                v-on:click="select(2, business)"
                class="account-item"
                v-for="business of businesses"
                :key="business.id"
              >
                <BaseIcon name="user" />
                <div>
                  <div class="account-item-name">{{ business.name }}</div>
                  <div class="account-item-type">Vállalkozás</div>
                </div>
              </button>
            </div>
          </div>
          <h2 v-on:click="accountSwitch = !accountSwitch">
            {{ selected.name }}
          </h2>
        </div>
      </div>
      <div class="boxes row">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="box box-tax-1" :class="{ show: active === 'home' }">
            <div class="box-item" v-if="data?.tax?.type === 'KATA'">
              <div class="money">
                {{
                  data?.tax?.kata.remaining.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">KATA keret</div>
            </div>
            <div class="box-item" v-if="data?.tax?.type === 'ALTALANY'">
              <div class="money">
                {{
                  data?.tax?.tax.remaining.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">Adó keret</div>
            </div>
            <div class="box-item">
              <div class="money">
                {{
                  data?.tax?.vat?.remaining.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">ÁFA keret</div>
            </div>
            <div class="box-item">
              <div class="money">
                {{
                  data?.tax?.hipa?.remaining.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">HIPA keret</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="box box-tax-2" :class="{ show: active === 'home' }">
            <div class="box-item" v-if="data?.tax?.type === 'KATA'">
              <div class="money">
                {{
                  data?.tax?.kata.amount.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">KATA</div>
            </div>
            <div class="box-item" v-if="data?.tax?.type === 'ALTALANY'">
              <div class="money">
                {{
                  data?.tax?.tax.amount.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">Adó</div>
            </div>
            <div class="box-item" v-if="data?.tax?.type === 'KATA'">
              <div class="money">
                {{
                  data?.tax?.kata.extra.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">KATA 40% extra</div>
            </div>
            <div class="box-item">
              <div class="money">
                {{
                  data?.tax?.hipa?.amount.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">HIPA</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div
            class="box box-cashflow"
            :class="{ show: active === 'transactions' }"
          >
            <div class="box-item">
              <div class="money">
                {{
                  data?.income?.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">Bevételek</div>
            </div>
            <div class="box-item">
              <div class="money">
                {{
                  data?.expense?.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">Kiadások</div>
            </div>
            <div class="box-item">
              <div class="money">0 <span class="currency">Ft</span></div>
              <div class="box-title">Követelések</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="box box-balance">
            <div class="box-item balance">
              <div class="money">
                {{
                  data.balance?.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">Egyenleg</div>
            </div>
            <div class="box-item">
              <div class="money">
                {{
                  data?.thisYear?.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
              <div class="box-title">Idén</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row page-content">
      <section class="col-12 col-lg-5" :class="{ show: active === 'home' }">
        <div class="box-container">
          <h3>Elemzések</h3>
          <div class="white-box">
            <div class="listitem row">
              <div class="col-auto">
                <div class="img">
                  <BaseIcon name="time" />
                </div>
              </div>
              <div class="col-3">Ebben a hónapban</div>
              <div class="col-4">
                <div class="money">
                  +{{
                    statstics?.thisMonth?.income?.toLocaleString("hu-HU", {
                      maximumFractionDigits: 0,
                    })
                  }}
                  <span class="currency">Ft</span>
                </div>
                <div class="money">
                  {{
                    statstics?.thisMonth?.expense?.toLocaleString("hu-HU", {
                      maximumFractionDigits: 0,
                    })
                  }}
                  <span class="currency">Ft</span>
                </div>
              </div>
              <div class="col money">
                {{
                  (
                    Number.parseInt(statstics?.thisMonth?.income) +
                    Number.parseInt(statstics?.thisMonth?.expense)
                  ).toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
            </div>
            <div class="listitem row">
              <div class="col-auto">
                <div class="img">
                  <BaseIcon name="calendar" />
                </div>
              </div>
              <div class="col-3">Múlt hónapban</div>
              <div class="col-4">
                <div class="money">
                  +{{
                    statstics?.lastMonth?.income?.toLocaleString("hu-HU", {
                      maximumFractionDigits: 0,
                    })
                  }}
                  <span class="currency">Ft</span>
                </div>
                <div class="money">
                  {{
                    statstics?.lastMonth?.expense?.toLocaleString("hu-HU", {
                      maximumFractionDigits: 0,
                    })
                  }}
                  <span class="currency">Ft</span>
                </div>
              </div>
              <div class="col money">
                {{
                  (
                    Number.parseInt(statstics?.lastMonth?.income) +
                    Number.parseInt(statstics?.lastMonth?.expense)
                  ).toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
            </div>
          </div>
        </div>
        <div class="box-container">
          <div class="d-flex">
            <h3 class="flex-1-1-0">Kategóriák</h3>
            <button
              class="btn btn-square"
              v-on:click="addCategoryModal()"
              title="Új hozzáadása"
            >
              <BaseIcon name="plus" />
            </button>
          </div>
          <div class="white-box">
            <div
              class="listitem row"
              v-for="category in statstics?.categories"
              :key="category.id"
            >
              <div class="col-auto">
                <div class="img">
                  <img :src="category.image" alt="" v-if="category.image" />
                  <BaseIcon
                    v-else-if="category.icon"
                    :name="category.icon"
                    :color="'#' + category.color"
                  />
                  <BaseIcon v-else name="lineCart" />
                </div>
              </div>
              <div class="col-3">{{ category.name }}</div>
              <div class="col-4">
                <div class="money" :class="{ income: category?.income > 0 }">
                  +{{
                    category.income?.toLocaleString("hu-HU", {
                      maximumFractionDigits: 0,
                    })
                  }}
                  <span class="currency">Ft</span>
                </div>
                <div class="money">
                  {{
                    category.expense?.toLocaleString("hu-HU", {
                      maximumFractionDigits: 0,
                    })
                  }}
                  <span class="currency">Ft</span>
                </div>
              </div>
              <div class="col money" :class="{ income: category?.balance > 0 }">
                {{
                  category.balance?.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
            </div>
          </div>
        </div>
        <div class="box-container">
          <h3>Ügyfelek</h3>
          <div class="white-box">
            <div
              class="listitem row"
              v-for="client in topClients"
              :key="client.id"
            >
              <div class="col-auto">
                <div class="img">
                  <img :src="client.image" alt="" v-if="client.image" />
                  <BaseIcon v-else-if="client.icon" :name="client.icon" />
                  <BaseIcon v-else name="lineCart" />
                </div>
              </div>
              <div class="col-3">{{ client.name }}</div>
              <div class="col-4">
                <div class="money" :class="{ income: client?.income > 0 }">
                  +{{
                    client.income?.toLocaleString("hu-HU", {
                      maximumFractionDigits: 0,
                    })
                  }}
                  <span class="currency">Ft</span>
                </div>
                <div class="money">
                  {{
                    client.expense?.toLocaleString("hu-HU", {
                      maximumFractionDigits: 0,
                    })
                  }}
                  <span class="currency">Ft</span>
                </div>
              </div>
              <div class="col money" :class="{ income: client?.balance > 0 }">
                {{
                  client.balance?.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="col-12 col-lg-7"
        :class="{ show: active === 'transactions' }"
      >
        <div class="box-container">
          <div class="d-flex">
            <h3 class="flex-1-1-0">Jövőbeli</h3>
            <button
              class="btn btn-square"
              v-on:click="addModal()"
              title="Új hozzáadása"
            >
              <BaseIcon name="plus" />
            </button>
            <button
              class="btn btn-square"
              v-if="selected.type == 1"
              v-on:click="exchangeModal()"
              title="Csoport módosítása"
            >
              <BaseIcon name="exchange" />
            </button>
            <a
              class="btn btn-square"
              v-if="selected.type == 2"
              :href="'/api/income-spreadsheets?id=' + selected.id"
              title="Bevételi nyilváltartás letöltése"
            >
              <BaseIcon name="downloadFile" />
            </a>
          </div>
          <div class="white-box">
            <div
              class="listitem row"
              :class="[item.duedate < now ? 'not-payed' : '']"
              v-for="item of future"
              :key="item.id"
            >
              <div class="col-auto">
                <button v-on:click="loadItem(item.id)" class="img">
                  <img :src="item.image" alt="" v-if="item.image" />
                  <BaseIcon
                    v-else-if="item.icon"
                    :name="item.icon"
                    :color="'#' + item.color"
                  />
                  <BaseIcon v-else name="wallet" />
                </button>
              </div>
              <div class="col-6 col-md-7">
                <button v-on:click="loadItem(item.id)" class="title">
                  {{ item.name }}
                </button>
                <div class="date d-block d-md-none">
                  {{ date(item.duedate) }}
                </div>
                <div class="date d-none d-md-block">{{ item.client }}</div>
              </div>
              <div class="d-none d-md-block col-md-2 date">
                {{ date(item.duedate) }}
              </div>
              <div class="col money" :class="{ income: item.totalInHuf > 0 }">
                {{
                  item.totalInHuf?.toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
                <div class="sub" v-if="item.currency !== 'HUF'">
                  {{ item.total }} {{ item.currency }}
                </div>
              </div>
              <div v-if="activeItem.id === item.id">
                <keep-alive>
                  <component
                    :is="activeItem.component"
                    v-bind:id="activeItem.id"
                  ></component>
                </keep-alive>
              </div>
            </div>
            <div v-if="future.length === 0" class="p-5 text-center">
              Nincs jövőbeli tranzakció
            </div>
          </div>
        </div>
        <div class="box-container">
          <div class="mb-4" v-for="(items, i) of cashflow" :key="i">
            <h3>{{ items.title }}</h3>
            <div class="white-box">
              <div
                class="listitem row"
                v-for="item of items.items"
                :key="item.id"
                :class="[
                  item.total > 0 &&
                  item.BusinessName &&
                  (item.invoiceNumber === null ||
                    item.invoiceNumber?.length < 1)
                    ? 'missing-data'
                    : '',
                ]"
              >
                <div class="col-auto">
                  <button v-on:click="loadItem(item.id)" class="img">
                    <img :src="item.image" alt="" v-if="item.image" />
                    <BaseIcon
                      v-else-if="item.icon"
                      :name="item.icon"
                      :color="'#' + item.color"
                    />
                    <BaseIcon v-else name="wallet" />
                  </button>
                </div>
                <div class="col-6 col-md-7">
                  <button v-on:click="loadItem(item.id)" class="title">
                    {{ item.name }}
                  </button>
                  <div class="date d-block d-md-none">
                    {{ date(item.payeddate) }}
                  </div>
                  <div class="date d-none d-md-block">{{ item.client }}</div>
                </div>
                <div class="d-none d-md-block col-md-2 date">
                  {{ date(item.payeddate) }}
                </div>
                <div class="col money" :class="{ income: item.totalInHuf > 0 }">
                  {{
                    item.totalInHuf?.toLocaleString("hu-HU", {
                      maximumFractionDigits: 0,
                    })
                  }}
                  <span class="currency">Ft</span>
                  <div class="sub" v-if="item.currency !== 'HUF'">
                    {{ item.total }} {{ item.currency }}
                  </div>
                </div>
                <div v-if="activeItem.id === item.id">
                  <keep-alive>
                    <component
                      :is="activeItem.component"
                      v-bind:id="activeItem.id"
                    ></component>
                  </keep-alive>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div
    ref="addNewModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Új hozzáadása</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <BaseIcon name="close" />
          </button>
        </div>
        <div class="modal-body row">
          <div class="input-group col-12">
            <select v-model="add.type">
              <option value="plus">Bevétel</option>
              <option value="minus">Kiadás</option>
            </select>
          </div>
          <div class="input-group col-6">
            <label>Vállalkozás</label>
            <select v-model="add.business">
              <option
                v-for="business of businesses"
                :key="business.id"
                :value="business.id"
              >
                {{ business.name }}
              </option>
            </select>
          </div>
          <div class="input-group col-6">
            <label>Csoport</label>
            <select v-model="add.group">
              <option v-for="group of groups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>
          <div class="input-group col-12">
            <label>Elem megnevezése</label>
            <input type="text" v-model="add.name" />
          </div>
          <div class="input-group col-12 col-lg-6 search-input">
            <label>Ügyfél</label>
            <input
              type="text"
              v-model="add.clientName"
              v-on:keydown="add.clientTax = null"
              placeholder="Név vagy adószám"
            />
            <div class="search-input-res" v-show="add.clientTax === null">
              <button
                v-for="client of add.clients"
                :key="client.id"
                v-on:click="selectClient(client)"
              >
                <div class="search-input-res-top">{{ client.name }}</div>
                <div class="search-input-res-bottom">
                  {{ client.taxNumber }} {{ client.country }}
                </div>
              </button>
            </div>
          </div>
          <div class="input-group col-12 col-lg-6">
            <label>Kategória</label>
            <select v-model="add.category">
              <option
                v-for="category of categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="col-12 input-group" v-if="add.newClient">
            <label>Ország</label>
            <select v-model="add.newClient.country">
              <option
                v-for="country in countries"
                :key="country.alpha2"
                :value="country.alpha2"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <div class="col-4 input-group" v-if="add.newClient">
            <label>Irányítószám</label>
            <input type="text" v-model="add.newClient.postCode" />
          </div>
          <div class="col-8 input-group" v-if="add.newClient">
            <label>Város</label>
            <input type="text" v-model="add.newClient.city" />
          </div>
          <div class="col-12 input-group" v-if="add.newClient">
            <label>Cím</label>
            <input type="text" v-model="add.newClient.address" />
          </div>
          <div class="input-group col-6">
            <label>Fizetési határidő</label>
            <input type="date" v-model="add.duedate" />
          </div>
          <div class="input-group col-6">
            <label>Fizetve</label>
            <input type="date" v-model="add.payeddate" />
          </div>
          <div class="input-group money-input col-12 col-lg-6">
            <label>Összeg</label>
            <input type="number" v-model="add.total" />
            <select v-model="add.currency">
              <option>HUF</option>
              <option>EUR</option>
              <option>USD</option>
              <option>GBP</option>
              <option>RON</option>
              <option>CZK</option>
            </select>
          </div>
          <div class="input-group col-12 col-lg-6">
            <label>Áfa</label>
            <select v-model="add.vat">
              <option disabled>-- Gyakoriak --</option>
              <option value="27%">27%</option>
              <option value="AAM">AAM - alanyi adómentes</option>
              <option value="THK">THK - területi hatályon kívül</option>
              <option disabled>-- Továbbiak --</option>
              <option value="TAM">TAM - tárgyi adómentes</option>
              <option value="KBA">KBA - adómentes közösségen belül</option>
              <option value="EAM">
                EAM - adómentes termékexport harmadik országba
              </option>
              <option value="NAM">
                NAM - adómentes egyéb nemzetközi ügyletekhez kapcsolódó jogcímen
              </option>
              <option value="FAD">FAD - fordított adózás</option>
              <option value="ÁTK">ÁTK - Áfa tárgyi hatályán kívül</option>
              <option value="26%">26%</option>
              <option value="25%">25%</option>
              <option value="24%">24%</option>
              <option value="23%">23%</option>
              <option value="22%">22%</option>
              <option value="21%">21%</option>
              <option value="20%">20%</option>
              <option value="19%">19%</option>
              <option value="18%">18%</option>
              <option value="17%">17%</option>
              <option value="16%">16%</option>
              <option value="15%">15%</option>
              <option value="14%">14%</option>
              <option value="13%">13%</option>
              <option value="12%">12%</option>
              <option value="11%">11%</option>
              <option value="10%">10%</option>
              <option value="9%">9%</option>
              <option value="8%">8%</option>
              <option value="7%">7%</option>
              <option value="6%">6%</option>
              <option value="5%">5%</option>
              <option value="4%">4%</option>
            </select>
          </div>
          <div class="input-group col-12">
            <label>Számla sorszáma</label>
            <input type="text" v-model="add.invoiceNumber" />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="addNew()"
            data-bs-dismiss="modal"
          >
            Hozzáadás
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    ref="ExchangeModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Csoport módosítása</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <BaseIcon name="close" />
          </button>
        </div>
        <div class="modal-body row">
          <div class="input-group col-12">
            <label>Csoport</label>
            <select v-model="change.group">
              <option v-for="group of groups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>
          <div class="input-group col-12">
            <label>Elem megnevezése</label>
            <input type="text" v-model="change.name" />
          </div>
          <div class="input-group col-12 col-lg-6">
            <label>Kategória</label>
            <select v-model="change.category">
              <option
                v-for="category of categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="input-group money-input col-12 col-lg-6">
            <label>Áthelyezendő összeg</label>
            <input type="number" v-model="change.total" />
            <select v-model="change.currency">
              <option>HUF</option>
              <option>EUR</option>
              <option>USD</option>
              <option>GBP</option>
              <option>RON</option>
              <option>CZK</option>
            </select>
          </div>
          <div class="input-group col-12">
            <label>Új csoport</label>
            <select v-model="change.groupTo">
              <option value="">Egyikbe se</option>
              <option v-for="group of groups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" v-on:click="changeGroup">Módosítás</button>
        </div>
      </div>
    </div>
  </div>
  <div
    ref="addCategoryModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Új kategória</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <BaseIcon name="close" />
          </button>
        </div>
        <div class="modal-body row">
          <div class="input-group col-12">
            <label>Kategória neve</label>
            <input type="text" v-model="addCategory.name" />
          </div>
          <div class="input-group col-12">
            <label>Kategória színe</label>
            <input type="color" v-model="addCategory.color" />
          </div>
          <div class="input-group col-12">
            <label>Kategória ikona</label>
            <div class="input-select-icon" v-for="(icon, i) in icons" :key="i">
              <input
                type="radio"
                :id="'cat-r-' + i"
                name="caticon"
                :value="icon"
                v-model="addCategory.icon"
              />
              <label :for="'cat-r-' + i">
                <BaseIcon :name="icon" />
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="addNewCategory()"
            data-bs-dismiss="modal"
          >
            Hozzáadás
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../../components/BaseIcon";
import { defineAsyncComponent } from "vue";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/hu";
const bootstrap = require("bootstrap");

export default {
  name: "Finance",
  components: { BaseIcon },
  data() {
    return {
      active: "home",
      accountSwitch: false,
      selected: {},
      data: {},
      businesses: [],
      groups: [],
      future: [],
      cashflow: [],
      statstics: [],
      clients: [],
      topClients: [],
      categories: [],
      countries: [],
      change: {
        group: 0,
        name: "",
        category: 0,
        total: 0,
        currency: "HUF",
        groupTo: "",
      },
      add: {
        type: "plus",
        business: 0,
        group: 0,
        name: "",
        category: 0,
        total: 0,
        currency: "HUF",
        vat: "AAM",
        duedate: "",
        payeddate: "",
        clientName: "",
        clientTax: null,
        clients: [],
        newClient: null,
        invoiceNumber: "",
      },
      addCategory: {
        name: "",
        color: "",
        icon: "wallet",
      },
      activeItem: {
        id: undefined,
        component: undefined,
      },
      icons: [
        "wallet",
        "handCoin",
        "bag",
        "shoppingBag",
        "priceTag",
        "safe",
        "coins",
        "basket",
        "terminal",
        "advert",
        "suitcase",
        "bank",
        "store",
        "office",
        "pencil",
        "server",
        "time",
        "calendar",
      ],
      months: [
        "január",
        "február",
        "március",
        "április",
        "május",
        "június",
        "július",
        "augusztus",
        "szeptember",
        "október",
        "november",
        "december",
      ],
    };
  },
  computed: {
    now() {
      return Math.floor(Date.now() / 1000);
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    "add.clientName"() {
      this.add.clients = [];

      if (this.add.clientName.length < 2) {
        return;
      }

      for (const client of this.clients) {
        if (
          client.name.toLowerCase().indexOf(this.add.clientName.toLowerCase()) >
            -1 ||
          client.taxNumber
            ?.toLowerCase()
            .indexOf(this.add.clientName.toLowerCase()) > -1
        ) {
          this.add.clients.push({
            id: client.id,
            name: client.name,
            taxNumber: client.taxNumber,
            country: client.country,
          });
        }
      }

      const data = new FormData();
      data.append("q", this.add.clientName);

      fetch(process.env.VUE_APP_API + "/search-business", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((response) => {
          for (const client of response) {
            this.add.clients.push(client);
          }
        })
        .catch((error) => {
          this.message = error;
        });
    },
  },
  methods: {
    loadItem(id) {
      if (this.activeItem.id === id) {
        this.activeItem.id = undefined;
        return;
      }
      if (screen.width < 768) {
        this.$router.push("/finance/" + id);
      } else {
        this.activeItem.id = id;
        this.activeItem.component = defineAsyncComponent(() =>
          import(`./FinanceItem.vue`)
        );
      }
    },
    addModal() {
      let myModal = new bootstrap.Modal(this.$refs.addNewModal, {
        backdrop: "static",
      });
      myModal.show();
    },
    selectClient(client) {
      if (client?.id !== undefined) {
        this.add.clientId = client.id;
      }
      this.add.clientName = client.name;
      this.add.clientTax = client.taxNumber;
      this.add.newClient = null;

      if (client.id === null || client.id === undefined) {
        this.add.newClient = {
          country: "",
          postCode: "",
          address: "",
          city: "",
        };
        this.add.newClient.country = client.country;
        if (client.address !== undefined) {
          this.add.newClient.postCode = client.postCode;
          this.add.newClient.address = client.address;
          this.add.newClient.city = client.city;
        } else {
          this.add.newClient.postCode = "";
          this.add.newClient.city = "";
          this.add.newClient.address = client?.companyAddress;
        }
      }
    },
    addCategoryModal() {
      let myModal = new bootstrap.Modal(this.$refs.addCategoryModal, {
        backdrop: "static",
      });
      myModal.show();
    },
    exchangeModal() {
      let myModal = new bootstrap.Modal(this.$refs.ExchangeModal, {
        backdrop: "static",
      });
      myModal.show();
    },
    addNewCategory() {
      const data = new FormData();
      data.append("name", this.addCategory.name);
      data.append("color", this.addCategory.color);
      data.append("icon", this.addCategory.icon);

      fetch(process.env.VUE_APP_API + "/add-finance-category", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((response) => {
          this.categories.push(response);
          response.income = 0;
          response.expense = 0;
          response.balance = 0;
          this.statstics.categories.push(response);
        })
        .catch((error) => {
          this.message = error;
        });
    },
    addNew() {
      const data = new FormData();
      data.append("name", this.add.name);
      data.append("category", this.add.category);
      data.append("vat", this.add.vat);
      data.append("group", this.add.group);
      data.append("business", this.add.business);
      data.append("duedate", this.add.duedate);
      data.append("payeddate", this.add.payeddate);
      data.append("total", this.add.total);
      data.append("currency", this.add.currency);
      data.append("clientTax", this.add.clientTax);
      if (this.add.clientId !== undefined) {
        data.append("client", this.add.clientId);
      }
      data.append("invoiceNumber", this.add.invoiceNumber);
      data.append("type", this.add.type);

      if (this.add.newClient !== null) {
        data.append("country", this.add.newClient.country);
        data.append("postCode", this.add.newClient.postCode);
        data.append("city", this.add.newClient.city);
        data.append("address", this.add.newClient.address);
        data.append("clientName", this.add.clientName);
      }

      fetch(process.env.VUE_APP_API + "/add-finance", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.payeddate === null) {
            this.future.unshift(response);
            return;
          }
          for (const i in this.cashflow) {
            const item = this.cashflow[i];
            if (this.month(item.date) === this.month(response.payeddate)) {
              this.cashflow[i].items.unshift(response);
              return;
            }
          }
          this.cashflow.push({
            title:
              this.year(response.date) +
              " " +
              this.months[this.month(response.date) - 1],
            items: [response],
          });
        })
        .catch((error) => {
          this.message = error;
        });
    },
    changeGroup() {
      const data = new FormData();
      data.append("name", this.change.name);
      data.append("category", this.change.category);
      data.append("group", this.change.group);
      data.append("total", this.change.total);
      data.append("currency", this.change.currency);
      data.append("groupTo", this.change.groupTo);

      fetch(process.env.VUE_APP_API + "/change-group", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.payeddate === null) {
            this.future.unshift(response);
            return;
          }
          for (const i in this.cashflow) {
            const item = this.cashflow[i];
            if (this.month(item.date) === this.month(response.payeddate)) {
              this.cashflow[i].items.unshift(response);
              return;
            }
          }
          this.cashflow.push({
            title:
              this.year(response.date) +
              " " +
              this.months[this.month(response.date) - 1],
            items: [response],
          });
        })
        .catch((error) => {
          this.message = error;
        });
    },
    fetchData(type = null, id = null) {
      fetch(process.env.VUE_APP_API + "/finance?type=" + type + "&id=" + id)
        .then((res) => res.json())
        .then((response) => {
          if (response.loginNeed) {
            this.$router.push("/login");
          }
          this.businesses = response.businesses;
          this.groups = response.groups;
          this.clients = response.clients;
          this.topClients = response.topClients;
          this.categories = response.categories;
          this.statstics = response.statstics;
          this.countries = response.countries;

          this.add.group = response.groups[0].id;
          this.add.business = response.businesses[0].id;
          this.add.category = response.categories[0].id;

          if (type === 1) {
            this.add.group = id;
          } else if (type === 2) {
            this.add.business = id;
          }

          this.future = [];
          this.cashflow = [];
          let pmonth = 0;
          for (const item of response.cashflow) {
            if (item.payeddate === null) {
              this.future.push(item);
              continue;
            }
            if (this.month(item.payeddate) !== pmonth) {
              this.cashflow.push({
                title:
                  this.year(item.payeddate) +
                  " " +
                  this.months[this.month(item.payeddate) - 1],
                items: [],
              });
              pmonth = this.month(item.payeddate);
            }
            this.cashflow[this.cashflow.length - 1].items.push(item);
          }

          this.data = response.data;
          if (type === null) {
            this.selected.name = "IntoMedia Group";
            this.selected.type = 0;
          }
        })
        .catch((error) => {
          this.message = error;
        });
    },
    select(type, data) {
      this.selected = data;
      this.selected.type = type;
      this.accountSwitch = false;
      this.fetchData(type, data.id);
    },
    month(val) {
      dayjs.extend(localizedFormat);
      dayjs.locale("hu");
      return dayjs.unix(val).format("M");
    },
    year(val) {
      dayjs.extend(localizedFormat);
      dayjs.locale("hu");
      return dayjs.unix(val).format("YYYY");
    },
    date(val) {
      dayjs.extend(localizedFormat);
      dayjs.locale("hu");
      dayjs.extend(relativeTime);
      if (
        dayjs.unix(val).format("YYYY") !==
          dayjs.unix(this.now).format("YYYY") ||
        dayjs.unix(val).format("M") !== dayjs.unix(this.now).format("M")
      ) {
        return dayjs.unix(val).format("L");
      }
      return dayjs().to(dayjs.unix(val));
    },
  },
};
</script>
